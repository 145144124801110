.ses-currentexercise-name {
    font-size: 12vw; /* 2rem; */
    font-weight: bold;
    align-content: center;
    text-align: center;
}
.ses-currentexercise-notes {
    font-size: 5vw; /* 1.2rem; */
    align-content: center;
    text-align: center;
    font-style: italic;
}
.ses-currentexercise-url {
    font-size: 5vw; /* 0.8rem; */
    align-content: center;
    text-align: center;
    font-style: italic;
}
.ses-currentexercise-weight {
    font-size: 5vw ; /* 1.2rem; */
    align-content: center;
    text-align: center;
    font-style: italic;
}