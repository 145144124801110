.rf-footer{
    /* background-color: #20232a; */
    background-color: #2C3531;
    left: 0;
    text-align: left;
    height: 2rem;
    color: white;
    font-size: 0.8rem;
    padding-left: 1rem;
}
.rf-footer-version {
    position: absolute !important;
    right: 2rem;
    display: inline;
}