.rf-dataeditor-text {
    width: 90%;
    height: 400px;
    background-color: darkslategrey;
    color: whitesmoke;
    font-size: small;
    margin: 20px 20px 20px 20px;
}
.rf-dataeditor-button{
    margin: 5px;
}