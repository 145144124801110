.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

hr {
  border: 1px solid black;
  border-radius: 5px;
}

.rf-app-container {
  position: relative;
  min-height: 100vh; /* must be relative to footer height */
}
.rf-app-content-wrap {
  padding-bottom: 2rem; /* must be relative to footer height */
}
.rf-app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height:2rem; /*footer height*/;
}
.rf-app-navbar-hidden {
  display: none;
}
.rf-app-text-uppercase {
  text-transform: uppercase !important;
}
.rf-app-text-center {
  text-align: center !important;
}