.sses-setcounter {
    /* font-size: 1.2rem; */
    font-size: 5vw;
    align-content: center;
    text-align: center;
    font-style: italic;
}
.sses-repstodo {
    /* font-size: 2.5rem; */
    font-size: 5vw;
    font-weight: bold;
    align-content: center;
    text-align: center;
}