.sr-restlabel {
    /* font-size: 1.6rem; */
    font-size: 12vw;
    font-weight: bold;
    align-content: center;
    text-align: center;
}
.sr-resttimer {
    /* font-size: 1.2rem; */
    font-size: 5vw;
    align-content: center;
    text-align: center;
    font-style: italic;
}
.sr-restnextinfo {
    /* font-size: 1.2rem; */
    font-size: 5vw;
    align-content: center;
    text-align: center;
    font-style: italic;
}