header.rf-home-masthead {
    padding-top: 10rem;
    padding-bottom: 6rem;

    padding-left: 0.3rem;
    padding-right: 0.3rem;

    text-align: center;
    color: #fff;
    background-image: url("/header-gb-clap-dark.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    background-size: cover;
}
.rf-home-container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}
header.rf-home-masthead .rf-home-masthead-subheading {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.5rem;
    margin-bottom: 25px;
    font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  header.rf-home-masthead .rf-home-masthead-heading {
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 3.25rem;
    margin-bottom: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  .rf-home-page-section {
    padding-top: 3rem;
    padding-right: 0.5rem;
    padding-bottom: 3rem;
    padding-left: 0.5rem;
  }
  .rf-home-page-section h2.rf-home-section-heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .rf-home-page-section h3.rf-home-section-subheading {
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin-bottom: 4rem;
  }
  .rf-home-btn-more {
    padding: 1.25rem 2.5rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.125rem;
    font-weight: 700;
  }
  .rf-home-muted {
    color: #6c757d !important;
  }
  .rh-home-svcs-title {
    margin-top: 1rem !important;
  }
  .rh-home-header-image {
    /* width: 400vmin; */
    max-width: 80%;
  }
  .rf-home-steps-num {
    font-size: 3.5rem;
    font-weight: bold;
  }