.awc-workout-active {
    background-color: lightgreen;
}
.awc-workout-ready {
    background-color: darkorange;
}
.awc-workout-stop {
    background-color: red;
}
.awc-workout-std {
    background-color: transparent;
}
.awo-buttonssection {
    text-align: center;
    /* position: absolute;
    bottom: 2rem; */
    /* left: 0; */
    /* width: 100%; */
    /* height:7rem;  */
    display: inline-block;
    flex-direction: column;
   align-items: center;  
   /* margin-top: 2rem;  */
}
.awo-button {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    /* padding: 0.1rem; */
    /* font-size: 2rem; */
}
.awo-workoutactionspanel {
    /* min-height: 50vh;   */
     /* height: 60vh;   */
     display: flex; 
     flex-direction: column;
    align-items: center;  
    font-size: 5vw /* 1.5rem */;
    margin-top: 2rem;   
}