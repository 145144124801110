.ssr-restlabel {
    font-size: 1.6rem;
    font-weight: bold;
    align-content: center;
    text-align: center;
}
.ssr-resttimer {
    font-size: 1.2rem;
    align-content: center;
    text-align: center;
    font-style: italic;
}